@import "../../styles/uiBase.scss";

.wrapper {
  @include AbsoluteFill();
  position: fixed;
  pointer-events: none;
}

.toast {
  position: absolute;
  top: 2rem;
  right: 2rem;
  pointer-events: all;
  animation: FadeInRightToLeft 0.5s ease-in-out;
}
