@import "size";

@mixin ScrollbarWidth($width: 10px) {
  &::-webkit-scrollbar {
    width: $width;
  }
}

@mixin ScrollbarThumb(
  $bgColor: rgba(gray, 0.7),
  $bgColorHover: gray,
  $trackColor: transparent,
  $borderRadius: $sizeS
) {
  &::-webkit-scrollbar-thumb {
    background-color: $bgColor;
    transition: all 0.2s;
    border-radius: $borderRadius;
    border: 2px solid $trackColor;

    &:hover {
      background-color: $bgColorHover;
    }
  }
}

@mixin ScrollbarTrack($bgColor: transparent) {
  &::-webkit-scrollbar-track {
    background-color: $bgColor;
    @content;
  }
}

@mixin SetRootValue($name, $value) {
  :root {
    #{$name}: $value;
  }
}

@mixin generateSizeClassNamesForProperty($property) {
  @each $sizeName, $sizeValue in $sizeMap {
    &.#{$sizeName} {
      #{$property}: $sizeValue;
    }
  }
}

@mixin AbsoluteFill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
