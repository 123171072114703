@import "../../../../../styles/uiBase.scss";

.image {
  max-width: 100%;
  max-height: 100%;
}

div.modalContent {
  min-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cropper {
  max-height: 100%;

  > :nth-child(1),
  > :nth-child(1) img {
    height: 100%;
  }
}
