@import "../../../styles/uiBase.scss";

$sidebarPadding: 0.75rem;

.sidebarWrapper {
  position: sticky;
  top: 0;
  padding: $sidebarPadding $sidebarPadding 0 $sidebarPadding;
  height: 100vh;
  width: 20rem;
  flex-shrink: 0;
}

.sidebar {
  background-color: rgb(226, 226, 226);
  border-radius: $borderRadiusL $borderRadiusL 0 0;
  height: 100%;
  padding: 2rem 2rem;
}
