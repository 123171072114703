@import "../../styles/uiBase.scss";

.field {
  width: 100%;
  max-width: $inputDefaultMaxWidth;
}

.label,
.helpLink {
  font-size: $fontSizeInputLabel;
}

.label {
  color: $colorInputLabel;
}

.error {
  color: red;
}
