@import "./uiBase.scss";
@import "./responsiveStyles.scss";

* {
  font-family: Arial, Helvetica, sans-serif;
  font-family: $fontFamilyPrimary;
  font-size: $fontSizeNormal;
  color: inherit;

  @include nonMobileNonTouchDevice() {
    @include ScrollbarWidth();
    @include ScrollbarThumb();
    @include ScrollbarTrack();
  }
}

html,
body,
#root,
.App {
  // height: 100%;
  position: relative;
}

html {
  color: $colorTextNormal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  font-size: $fontSizeHeading1;
  color: $colorTextHeading1;
}

h2 {
  font-size: $fontSizeHeading2;
  color: $colorTextHeading2;
}

h3 {
  font-size: $fontSizeHeading3;
  color: $colorTextHeading3;
}

h4 {
  font-size: $fontSizeHeading4;
  color: $colorTextHeading4;
}

h5 {
  font-size: $fontSizeHeading5;
  color: $colorTextHeading5;
}

h6 {
  font-size: $fontSizeHeading6;
  color: $colorTextHeading6;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /* Ensures that autofill styles don't get applied while user is still on page */
  transition:
    all 99999s 99999s,
    border-color 0.25s;
}
