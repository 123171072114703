@import "../../../styles/uiBase.scss";

.imageThumbnail {
  border-radius: $borderRadiusS;
  cursor: pointer;

  &:hover {
    .img {
      transform: scale(1.05);
    }
  }

  .img {
    transition: $transitionDefault;
  }
}
