@import "../../../styles/uiBase.scss";

.nav {
  padding: $navBarVerticalPadding $navBarHorizontalPadding;
  align-self: start;
}

.pageTitle {
  font-size: $fontSizeHeading2;
}
