@import "../../styles/uiBase.scss";

$btnColors: (
  primary: (
    bg: $colorButtonBgPrimary,
    text: $colorButtonBgPrimaryText,
    disabled: $colorButtonBgPrimaryDisabled,
    disabledText: $colorButtonBgPrimaryDisabledText,
    hover: $colorButtonBgPrimaryHover,
  ),
  secondary: (
    bg: $colorButtonBgSecondary,
    disabled: $colorButtonBgSecondaryDisabled,
    disabledText: $colorButtonBgSecondaryDisabledText,
    hover: $colorButtonBgSecondaryHover,
    border: $colorButtonBgSecondaryBorder,
  ),
);

@mixin ButtonVariants() {
  @each $variant, $colors in $btnColors {
    &.#{$variant} {
      background-color: map-get($colors, bg);
      color: map-get($colors, text);
      border-color: map-get($colors, border);

      &:disabled {
        background-color: map-get($colors, disabled);
        color: map-get($colors, disabledText);
      }

      &:hover:not(:disabled) {
        background-color: map-get($colors, hover);
      }
    }
  }
}

.button {
  position: relative;
  padding: $inputPaddingVertical $inputPaddingHorizontal;
  border-radius: $buttonBorderRadius;
  border: 2px solid transparent;
  transition: all 0.15s;

  @include ButtonVariants();

  &.loading {
    .spinnerWrapper {
      display: flex;
    }

    .text {
      opacity: 0;
    }
  }
}

.spinnerWrapper {
  @include AbsoluteFill();
  pointer-events: none;
  display: none;
  transition: all 0s;
}
