@import "../../../styles/uiBase.scss";

.rightContent {
  height: 100vh;
  flex-grow: 1;
}

.main,
.main .loadingOverlay {
  background-color: rgb(226, 226, 226);
}

.main {
  position: relative;
  flex-grow: 1;
  padding: $pageContentPaddingVertical $pagePaddingHorizontal;
  border-top: 2px solid rgb(226, 226, 226);
  border-top-left-radius: $borderRadiusL;
  overflow: auto;
}
