@import "../../styles/uiBase.scss";

.gridItem {
}

.listingCard {
  display: block;
  width: 100%;
  background-color: $colorBgPrimary;
  padding: $sizeS;
  border-radius: $borderRadiusM;
  border: 2px solid transparent;
  transition: $transitionDefault;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgb(255, 255, 255);
}
